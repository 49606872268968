<template>
  <div class="landing-page-check-report__for-background">
    <div
      class="landing-page__background landing-page__background--check-report-left"
    >
      <img
        alt="background main"
        src="~@/assets/images/background.png"
      >
    </div>
    <div
      class="landing-page__background landing-page__background--check-report-right"
    >
      <img
        alt="background main"
        src="~@/assets/images/background.png"
      >
    </div>
    <div class="landing-page-check-report">
      <div class="landing-page-check-report-head-text">
        Check <span class="bluet-text">your</span> or
        <span class="bluet-text">any</span> address in the blockchain
      </div>
      <div>
        <SourceSearchBox
          ref="SourceSearchBoxRef"
          @data-result="setDataSearchResult"
        />
        <SourceResult
          :data-search-result="dataSearchResult"
          :is-result-seted="isResultSeted"
          @update-package-by-key="updateDataSearchResultByKey"
          @update-search-data="updateSearchData"
        />
      </div>
    </div>
  </div>
</template>

<script>
//Components
import SourceSearchBox from '@/pages/source-of-funds/components/SourceSearchBox.vue'
import SourceResult from '@/pages/source-of-funds/components/SourceResult.vue'

export default {
  components: {
    SourceSearchBox,
    SourceResult,
  },
  data() {
    return {
      isResultSeted: false,
      dataSearchResult: [],
    }
  },
  methods: {
    setDataSearchResult(data) {
      this.isResultSeted = Boolean(data.length)
      this.dataSearchResult = data
    },
    updateDataSearchResultByKey(e) {
      this.dataSearchResult = this.dataSearchResult.map(item => {
        if (e.reportPartName == item.reportPartName) {
          return { ...e }
        } else {
          return { ...item }
        }
      })
    },
    updateSearchData({ value, recalc }) {
      this.$refs.SourceSearchBoxRef.searchData(value, recalc)
    },
  },
}
</script>

<style>
.landing-page-check-report__for-background {
  width: var(--main-content-width);
  max-width: var(--main-content-max-width);
  position: relative;
}
.landing-page-check-report {
  background-color: var(--white);
  box-shadow: 0px 0px 10px 0px var(--dark-transparent-1a);
  padding: 60px;
  border-radius: 24px;
  position: relative;
  z-index: 2;
}

.landing-page-check-report-head-text {
  font-size: 32px;
  font-weight: 700;
  color: var(--dark-text);
  margin-bottom: 30px;
}

.landing-page-check-report .v-select {
  border: 1px solid var(--reflex-bluet);
}

.landing-page-check-report .coin-select-base .vs__dropdown-toggle {
  min-height: 50px;
  width: 150px;
  box-shadow: 0px 0px 15px 0px var(--dark-transparent);
}

@media (max-width: 1279px) {
  .landing-page-check-report {
    padding: 16px;
  }
}
@media (max-width: 1023px) {
  .landing-page-check-report {
    max-width: 600px;
    margin: 0 auto;
  }
  .landing-page-check-report-head-text {
    margin-bottom: 16px;
    font-size: 24px;
  }

  .landing-page-check-report .gl-search-box-button {
    min-width: 150px;
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .landing-page-check-report {
    max-width: 100%;
  }
  .landing-page-check-report .gl-search-box-button {
    min-width: unset;
    font-size: unset;
  }
}
</style>
