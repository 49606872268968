<template>
  <div class="landing-page-about-us">
    <div class="landing-page-about-us-head-text">
      About Us
    </div>
    <div class="landing-page-about-us-sub-head-text">
      Why Satoshi Eye
    </div>
    <div class="flex m-display-block gap-30 space-between">
      <div
        v-for="data in cardsData"
        :key="data.count"
        class="landing-page-about-us-card"
      >
        <div class="landing-page-about-us-card-count">
          {{ data.count }}
        </div>
        <div class="landing-page-about-us-card-head-text">
          {{ data.headText }}
        </div>
        <div class="landing-page-about-us-card-text">
          {{ data.cardText }}
        </div>
        <div v-if="data.footnote" class="landing-page-about-us-card-footnote">
          {{ data.footnote }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//Components

export default {
  data() {
    return {
      cardsData: [
        {
          count: 1,
          headText: '1-Click Results',
          cardText:
            'Enter wallet address, get results, done. No complex setups, get the data you need immediately. Make informed crypto decisions quickly.',
        },
        {
          count: 2,
          headText: 'More Data',
          cardText:
            'In the real world, this means that we identify 85-95%* of the risk involved. Uncover hidden connections to see the bigger picture behind a wallet’s history.',
          footnote: '*In most cases',
        },
        {
          count: 3,
          headText: 'Unbeatable Price',
          cardText:
            'Get capabilities of banks, governments, law enforcement for a few bucks. No need to pay extra fees for professional-grade data. You receive the power of the pros, without paying like one.',
        },
      ],
    }
  },
}
</script>

<style>
.landing-page-about-us {
  display: flex;
  flex-direction: column;
  width: var(--main-content-width);
  max-width: var(--main-content-max-width);
}
.landing-page-about-us-head-text {
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  color: var(--dark-text-50);
  margin-bottom: 30px;
}
.landing-page-about-us-sub-head-text {
  text-align: center;
  font-size: 60px;
  font-weight: 700;
  color: var(--dark-text);
  margin-bottom: 90px;
}

.landing-page-about-us-card {
  display: flex;
  flex-direction: column;
  gap: 30px;
  flex: 1;
  background: var(--white);
  padding: 60px;
  box-shadow: 0px 0px 10px 0px var(--dark-transparent-1a);
  border-radius: 24px;
}
.landing-page-about-us-card-count {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  font-size: 27px;
  font-weight: 700;
  color: var(--white);
  border-radius: 50%;
  background: var(--reflex-bluet);
}
.landing-page-about-us-card-head-text {
  margin-right: -30px;
  font-size: 36px;
  font-weight: 700;
  color: var(--dark-text);
}
.landing-page-about-us-card-text {
  font-size: 18px;
  color: var(--dark-text);
  line-height: 27px;
}
.landing-page-about-us-card-footnote {
  font-size: 18px;
  color: var(--light-gray-text);
}


@media (max-width: 1279px) {
  .landing-page-about-us-head-text {
    margin-bottom: 16px;
    font-size: 24px;
    text-align: left;
  }
  .landing-page-about-us-sub-head-text {
    margin-bottom: 24px;
    font-size: 32px;
    text-align: left;
  }
  .landing-page-about-us-card {
    grid-gap: 24px;
    gap: 24px;
    padding: 24px;
  }
  .landing-page-about-us-card-head-text {
    margin-right: 0;
    font-size: 24px;
  }
  .landing-page-about-us-card-text {
    font-size: 16px;
    line-height: 24px;
  }
  .landing-page-about-us-card-footnote {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .landing-page-about-us-card + .landing-page-about-us-card {
    margin-top: 24px;
  }
}
</style>
