<template>
  <div class="landing-page-get-report">
    <div class="landing-page__background landing-page__background--main">
      <img 
        alt="background main"
        src="~@/assets/images/background.png" 
      >
    </div>
    <div class="landing-page__left flex column">
      <div class="landing-page-get-report-head-text">
        Top-Tier <span class="bluet-text">Crypto AML Reports</span> from €0.3
      </div>
      <div class="landing-page-get-report-main-text">
        See what exchanges see, fight back hackers and scammers, get insights about your counterparties.
      </div>
      <div class="landing-page-get-report-button-wrap">
        <Button
          class="min-w-220 height-56 bold-600 fs-18"
          label="Free Checkup"
          @click="$router.push({ name: 'source-of-funds' })"
        />
      </div>
    </div>

    <div class="landing-page__images">
      <div class="landing-page__images-item landing-page__images-item--center">
        <img 
          alt="Landing page get report image 2" 
          src="~@/assets/images/new-design-img2.png" 
        >
      </div>
      <div class="landing-page__images-item landing-page__images-item--left-top">
        <img 
          alt="Landing page get report image 1" 
          src="~@/assets/images/new-design-img1.png"
        >
      </div>
      <div class="landing-page__images-item landing-page__images-item--right-top">
        <img 
          alt="Landing page get report image 3" 
          src="~@/assets/images/new-design-img3.png"
        >
      </div>
      <div class="landing-page__images-item landing-page__images-item--left-bottom">
        <img 
          alt="Landing page get report image 4" 
          src="~@/assets/images/new-design-img4.png"
        >
      </div>
      <div class="landing-page__images-item landing-page__images-item--right-bottom">
        <img 
          alt="Landing page get report image 5" 
          src="~@/assets/images/new-design-img5.png"
        >
      </div>
    </div>
  </div>
</template>

<script>
//Components
import Button from 'primevue/button'

export default {
  components: {
    Button,
  },
}
</script>

<style>
/* get-report */
.landing-page-get-report-wrapper {
  display: flex;
  justify-content: center;
  padding: 25px 0 25px 0;
}
.landing-page-get-report {
  display: flex;
  justify-content: space-between;
  width: var(--main-content-width);
  max-width: var(--main-content-max-width);
  position: relative;
}
.landing-page-get-report-head-text {
  font-size: 46px;
  font-weight: 700;
  line-height: 57.5px;
  color: var(--dark);
  max-width: 550px;
  margin: 96px 0 20px 0;
}
.landing-page-get-report-main-text {
  font-size: 18px;
  line-height: 27px;
  color: var(--dark);
  max-width: 580px;
  margin-bottom: 40px;
}

.landing-page__left {
  flex: 0 0 calc(40% - 15px);
  position: relative;
  z-index: 2;
}
.landing-page__images {
  flex: 0 0 calc(60% - 15px);
  aspect-ratio: 720 / 650;
  position: relative;
  z-index: 3;
  pointer-events: none;
}
.landing-page__images-item {
  box-shadow: 0 8px 40px 0 rgba(229, 235, 240, 1);
  border-radius: 16px;
  position: absolute;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
.landing-page__images-item img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.landing-page__images-item--center {
  aspect-ratio: 582 / 331;
  width: 80%;
  right: 5%;
  bottom: 12%;
  z-index: 1;
  animation-name: floating-center;
  animation-duration: 9s;
}
.landing-page__images-item--left-top {
  aspect-ratio: 200 / 156;
  width: 28%;
  padding: 12px 16px;
  background-color: var(--white-transparent);
  backdrop-filter: blur(5px);
  left: 6%;
  top: 6%;
  z-index: 2;
  animation-name: floating-left-top;
  animation-duration: 5s;
}
.landing-page__images-item--right-top {
  aspect-ratio: 441 / 279;
  width: 61%;
  padding: 24px 20px;
  background-color: var(--white);
  right: 0;
  top: 0;
  z-index: 2;
  animation-name: floating-right-top;
  animation-duration: 6s;
}
.landing-page__images-item--left-bottom {
  aspect-ratio: 203 / 130;
  width: 28%;
  padding: 16px;
  background-color: var(--white);
  left: 0;
  bottom: 3%;
  z-index: 2;
  animation-name: floating-left-bottom;
  animation-duration: 4s;
}
.landing-page__images-item--right-bottom {
  aspect-ratio: 210 / 183;
  width: 29%;
  padding: 24px;
  background-color: var(--white);
  right: 0;
  bottom: 0;
  z-index: 2;
  animation-name: floating-right-bottom;
  animation-duration: 7s;
}
@keyframes floating-center {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, 5px);
  }
  100% {
    transform: translate(0, 0);
  }    
}
@keyframes floating-left-top {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, 8px);
  }
  100% {
    transform: translate(0, 0);
  }    
}
@keyframes floating-right-top {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, -6px);
  }
  100% {
    transform: translate(0, 0);
  }    
}
@keyframes floating-left-bottom {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, -7px);
  }
  100% {
    transform: translate(0, 0);
  }    
}
@keyframes floating-right-bottom {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, 9px);
  }
  100% {
    transform: translate(0, 0);
  }    
}



@media (max-width: 1279px) {
  .landing-page-get-report-wrapper {
    padding: 0;
  }
  .landing-page-get-report {
    display: block;
  }
  .landing-page__left {
    margin-bottom: 36px;
  }
  .landing-page-get-report-head-text {
    max-width: 100%;
    margin: 48px 0 24px 0;
  }
  .landing-page-get-report-main-text {
    max-width: 100%;
    margin-bottom: 24px;
  }
  .landing-page-get-report-button-wrap {
    display: flex;
    justify-content: center;
  }
  .landing-page__images {
    max-width: 800px;
    margin: 0 auto;
  }
}
@media (max-width: 1023px) {
  .landing-page-get-report-head-text {
    font-size: 32px;
    line-height: 40px;
  }
}
@media (max-width: 767px) {
  .landing-page-get-report-button-wrap .min-w-220 {
    min-width: 100%;
  }
  .landing-page__images {
    aspect-ratio: 720 / 1000;
  }
  .landing-page__images-item--center {
    width: 100%;
    right: 0;
    bottom: 20%;
  }
  .landing-page__images-item--left-top {
    width: 38%;
    padding: 6px 8px;
    left: 0;
    top: 30%;
    z-index: 5;
  }
  .landing-page__images-item--right-top {
    width: 90%;
    padding: 12px 10px;
  }
  .landing-page__images-item--left-bottom {
    width: 42%;
    padding: 8px;
    bottom: 2%;
  }
  .landing-page__images-item--right-bottom {
    width: 42%;
    padding: 12px;
  }
}
</style>
