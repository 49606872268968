<template>
  <div class="youtube__wrap">
    <youtube
      player-height="100%"
      player-width="100%"
      :video-id="youtubeID"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      youtubeID: 'MteOdfIMuVg'
    }
  },
}
</script>

<style scoped>
.youtube__wrap {
  display: grid;
  aspect-ratio: 16 / 9;
  width: var(--main-content-width);
  max-width: var(--main-content-max-width);
  box-shadow: 0 0 10px 0 var(--dark-transparent-1a);
  border-radius: 24px;
  overflow: hidden;
}
</style>
