<template>
  <footer class="landing-page-footer white-text">
    <div class="landing-page-footer__first-block">
      <div>
        <SatoshiEyeLogoLight class="logo" />
      </div>
      <div>
        <span
          class="white-transparent-text"
        >If you have any questions, contact us:</span>
        <br>
        <a
          class="landing-page-footer-social-link"
          href="mailto:support@satoshieye.com"
        >support@satoshieye.com</a>
      </div>
    </div>
    <div class="landing-page-footer-text-wrapper">
      <div class="white-transparent-text">
        Info
      </div>
      <div
        class="landing-page-footer__scroll-link"
        @click="scrollToSelectorItem('.main', '.landing-page-features-wrapper')"
      >
        Features
      </div>
      <div
        class="landing-page-footer__scroll-link"
        @click="
          scrollToSelectorItem('.main', '.landing-page-pricing__for-background')
        "
      >
        Pricing
      </div>
      <div
        class="landing-page-footer__scroll-link"
        @click="scrollToSelectorItem('.main', '.landing-page-faq-wrapper')"
      >
        FAQ
      </div>
    </div>
    <div class="landing-page-footer-text-wrapper">
      <div class="white-transparent-text">
        Legal
      </div>
      <div>
        <router-link
          class="landing-page-footer-link"
          :to="{ name: 'terms-of-use' }"
        >
          Terms of Use
        </router-link>
      </div>
      <div>
        <router-link
          class="landing-page-footer-link"
          :to="{ name: 'privacy-policy' }"
        >
          Privacy Policy
        </router-link>
      </div>
      <div>
        <router-link
          class="landing-page-footer-link"
          :to="{ name: 'cookie-policy' }"
        >
          Cookie Policy
        </router-link>
      </div>
    </div>
    <div class="landing-page-footer-social-wrapper">
      <div class="white-transparent-text">
        Social networks
      </div>
      <a
        class="flex align-center landing-page-footer-social-link"
        href="https://www.linkedin.com/company/satoshi-eye/"
        target="_blank"
      >
        <div class="landing-page-footer-social-icon-wrap mr-2">
          <gl-icon
            :height="20"
            name="linkedin"
            :width="20"
          />
        </div>
        LinkedIn
      </a>
      <a
        class="flex align-center landing-page-footer-social-link"
        href="https://twitter.com/Satoshi_Eye"
        target="_blank"
      >
        <gl-icon
          class="mr-2"
          :height="32"
          name="twitter"
          :width="32"
        /> Twitter
      </a>
    </div>
  </footer>
</template>

<script>
//Components
import GlIcon from '@/components/gl-icon'
import SatoshiEyeLogoLight from '@/assets/svg/header/SatoshiEyeLogoLight.svg?inline'
import { scrollToSelector } from '@/utils/scroll-to'

export default {
  components: {
    GlIcon,
    SatoshiEyeLogoLight,
  },
  computed: {
    headerHeight() {
      return getComputedStyle(document.documentElement).getPropertyValue(
        '--header-height',
      )
    },
    reportBannerHeight() {
      return getComputedStyle(document.documentElement).getPropertyValue(
        '--report-banner-height',
      )
    },
  },
  methods: {
    scrollToSelector,
    scrollToSelectorItem(wrap, aim) {
      this.scrollToSelector(
        wrap,
        aim,
        parseInt(this.headerHeight) + parseInt(this.reportBannerHeight),
      )
    },
  },
}
</script>

<style>
.landing-page-footer {
  display: flex;
  gap: 24px;
  justify-content: space-between;
  width: var(--main-content-width);
  max-width: var(--main-content-max-width);
}

.landing-page-footer .logo {
  width: 175px;
  padding-bottom: 30px;
}
.landing-page-footer-text-wrapper > div {
  margin-bottom: 36px;
}
.landing-page-footer-social-wrapper > div {
  margin-bottom: 30px;
}
.landing-page-footer-link,
.landing-page-footer__scroll-link,
.landing-page-footer-social-link {
  color: var(--white);
  text-decoration: none;
  transition: color 0.3s ease;
  cursor: pointer;
}
.landing-page-footer-link:hover,
.landing-page-footer__scroll-link:hover,
.landing-page-footer-social-link:hover {
  color: var(--reflex-bluet);
}

.landing-page-footer-social-link + .landing-page-footer-social-link {
  margin-top: 24px;
}
.landing-page-footer-social-icon-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background-color: var(--white-10);
  border-radius: 8px;
}

@media (max-width: 1023px) {
  .landing-page-footer {
    flex-wrap: wrap;
  }
  .landing-page-footer__first-block {
    min-width: 100%;
  }
}

@media (max-width: 767px) {
  .landing-page-footer {
    justify-content: start;
    gap: 0;
  }
  .landing-page-footer .logo {
    padding-bottom: 16px;
  }
  .landing-page-footer__first-block {
    margin-bottom: 32px;
  }
  .landing-page-footer-text-wrapper {
    min-width: 50%;
    margin-bottom: 24px;
  }
  .landing-page-footer-text-wrapper > div {
    margin-bottom: 16px;
  }
  .landing-page-footer-social-wrapper {
    min-width: 100%;
  }
  .landing-page-footer-social-wrapper > div {
    margin-bottom: 16px;
  }
}
</style>
