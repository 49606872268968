<template>
  <GlPageWrap>
    <div class="landing-page-wrapper">
      <div class="landing-page-get-report-wrapper">
        <Intro />
      </div>
      <div class="landing-page-check-report-wrapper">
        <CheckReport />
      </div>
      <div class="landing-page-features-wrapper">
        <Features />
      </div>
      <div class="landing-page-about-us-wrapper">
        <AboutUs />
      </div>
      <div class="landing-page-pricing__for-background">
        <div class="landing-page__background landing-page__background--pricing-top">
          <img 
            alt="background main"
            src="~@/assets/images/background.png" 
          >
        </div>
        <div class="landing-page__background landing-page__background--pricing-bottom">
          <img 
            alt="background main"
            src="~@/assets/images/background.png" 
          >
        </div>
        <div class="landing-page-pricing-wrapper">
          <Pricing />
        </div>
      </div>
      <div 
        v-if="true" 
        class="landing-page-subscribe-wrapper"
      >
        <Subscribe />
      </div>
      <div
        id="landing-page-faq"
        class="landing-page-faq-wrapper"
      >
        <FAQ />
      </div>
      <div 
        v-if="false" 
        class="landing-page-youtube-video-wrapper"
      >
        <YouTubeVideo />
      </div>
      <div class="landing-page-footer-wrapper">
        <LandingFooter />
        <div class="landing-page-footer-copyright">
          <div class="landing-page-footer-copyright-text">© 2024 Satoshi Eye. All Rights reserved</div>
        </div>
      </div>
    </div>
  </GlPageWrap>
</template>

<script>
//components
import GlPageWrap from '@/components/layout/gl-page-wrap'
import Intro from '@/pages/landing-page/components/Intro'
import LandingFooter from '@/pages/landing-page/components/LandingFooter'
import AboutUs from '@/pages/landing-page/components/AboutUs'
import Pricing from '@/pages/landing-page/components/Pricing'
import FAQ from '@/pages/landing-page/components/FAQ'
import Subscribe from '@/pages/landing-page/components/Subscribe'
import Features from '@/pages/landing-page/components/Features'
import CheckReport from '@/pages/landing-page/components/CheckReport'
import YouTubeVideo from '@/pages/landing-page/components/YouTubeVideo'

export default {
  name: 'LandingPage',
  components: {
    GlPageWrap,
    Intro,
    LandingFooter,
    AboutUs,
    Pricing,
    FAQ,
    Subscribe,
    Features,
    CheckReport,
    YouTubeVideo,
  },
}
</script>

<style>
.landing-page-wrapper {
  margin-top: calc(var(--header-height) + var(--report-banner-height));
  font-family: 'Exo 2', 'Montserrat', sans-serif;
  display: flex;
  flex-direction: column;
  background-color: var(--light-grey-f-3);
}

/* features */
.landing-page-check-report-wrapper {
  margin: 30px 0 150px 0;
  position: relative;
  z-index: 2;
}
.landing-page-check-report-wrapper .gl-input__input {
  border: 1px solid var(--reflex-bluet);
}
/* features */
.landing-page-features-wrapper {
  margin: 30px 0 150px 0;
}

/* about-us */
.landing-page-about-us-wrapper {
  margin: 30px 0 200px 0;
  position: relative;
  z-index: 2;
}

/* pricing */
.landing-page-pricing__for-background {
  position: relative;
}
.landing-page-pricing-wrapper {
  background: var(--dark);
  padding: 90px 0 75px 0;
  position: relative;
  z-index: 2;
}

/* subscribe */
.landing-page-subscribe-wrapper {
  padding: 90px 0 0 0;
  position: relative;
  z-index: 2;
}

/* faq */
.landing-page-faq-wrapper {
  padding: 150px 0 120px 0;
  position: relative;
  z-index: 2;
}

/* youtube */
.landing-page-youtube-video-wrapper {
  padding-bottom: 90px;
}

/* footer */
.landing-page-footer-wrapper {
  background: var(--dark);
  padding: 48px 0 200px 0;
  position: relative;
}

.landing-page-check-report-wrapper,
.landing-page-features-wrapper,
.landing-page-about-us-wrapper,
.landing-page-pricing-wrapper,
.landing-page-subscribe-wrapper,
.landing-page-youtube-video-wrapper,
.landing-page-faq-wrapper,
.landing-page-footer-wrapper {
  display: flex;
  justify-content: center;
}

.landing-page-footer-copyright {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 32px;
  background-color: var(--white-10);
  position: absolute;
  left: 0;
  bottom: 0;
}
.landing-page-footer-copyright-text {
  font-size: 14px;
  line-height: 1;
  color: var(--white);
}

@media (max-width: 1279px) {
  .landing-page-check-report-wrapper {
    margin-bottom: 60px;
  }
  .landing-page-features-wrapper {
    margin: 0 0 60px 0;
  }
  .landing-page-about-us-wrapper {
    margin: 0 0 60px 0;
  }
  .landing-page-pricing-wrapper {
    padding: 60px 0;
  }
  .landing-page-subscribe-wrapper {
    padding: 60px 0 0 0;
  }
  .landing-page-faq-wrapper {
    padding: 60px 0;
  }
  .landing-page-youtube-video-wrapper {
    padding-bottom: 60px;
  }
  .landing-page-footer-wrapper {
    padding: 24px 0 64px 0;
  }
}
</style>
