<template>
  <div class="landing-page-faq">
    <div class="landing-page-faq-head-text">
      Need a little more help?
    </div>
    <div class="landing-page-faq-sub-head-text">
      Crypto AML FAQs
    </div>
    <div class="landing-page-faq-accordion-wrapeer">
      <Accordion>
        <AccordionTab
          v-for="tab in faqData"
          :key="tab.title"
        >
          <template #header>
            <span>{{ tab.title }}</span>
          </template>
          <p v-html="tab.content"></p>
        </AccordionTab>
      </Accordion>
    </div>
  </div>
</template>

<script>
//Components
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'

export default {
  components: {
    Accordion,
    AccordionTab,
  },
  data() {
    return {
      faqData: [
        {
          title: 'Why should I trust your risk score/reports?',
          content:
            '<p>Our information comes from two reliable sources. First, we use blockchain, which is straightforward to check. Second, we gather details about entities and their addresses on our own. This means we can offer solid proof to show that our data is accurate and trustworthy. Integrity statement can be provided on request.</p><p>And the most important thing: We don’t share data, we collect it, if you know what we mean.</p>',
        },
        {
          title: 'Which blockchains does Satoshi Eye analyze?',
          content:
            '<p>Satoshi Eye checks Bitcoin at the moment, with Litecoin and BSV coming next. Ethereum and Tron will be added later.</p>',
        },
        {
          title: 'What is a Lookup?',
          content:
            '<p>A Lookup is the in-app currency you use within Satoshi Eye to buy reports. Here\'s the deal:</p><p>1) The more Lookups you buy at once, the cheaper you get them per report.</p><p>2) The more Lookups you spend, the more detailed report you can get.</p>',
        },
        {
          title: 'What if coins come from mixing?',
          content:
            '<p>If your received crypto came from mixing — rules from the FATF say it\'s high-risk. The compliance team that checks if transactions meet the rules will consider it a red flag. It means they might freeze your funds.</p>',
        },
        {
          title: 'Can my funds get frozen?',
          content:
            '<p>Yes, there are risks involved with crypto transactions because funds can come from different sources.</p><p>You can avoid these problems using Satoshi Eye to ensure the funds you receive are clean.</p>',
        },
        {
          title: 'Could I use your service if I am a hacker, darknet vendor or Russian oligarch?',
          content:
            '<p>No, we are the good guys. We warned you!</p>',
        },
      ],
    }
  },
}
</script>

<style>
.landing-page-faq {
  display: flex;
  flex-direction: column;
  width: var(--main-content-width);
  max-width: var(--main-content-max-width);
}
.landing-page-faq-head-text {
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  color: var(--dark-text);
  margin-bottom: 30px;
}
.landing-page-faq-sub-head-text {
  text-align: center;
  font-size: 60px;
  font-weight: 700;
  color: var(--dark-text);
  margin-bottom: 60px;
}

.landing-page-faq .p-accordion-toggle-icon {
  width: 24px;
  height: 24px;
  background: var(--reflex-bluet);
  border-radius: 100%;
  position: absolute;
  right: 0;
}
.landing-page-faq .pi-chevron-down:before,
.landing-page-faq .pi-chevron-right:before {
  content: "";
  width: 12px;
  height: 2px;
  background-color: var(--white);
  border-radius: 2px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.landing-page-faq .pi-chevron-down:after,
.landing-page-faq .pi-chevron-right:after {
  content: "";
  width: 2px;
  height: 12px;
  background-color: var(--white);
  border-radius: 2px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.4s ease;
}
.landing-page-faq .p-accordion-tab-active .pi-chevron-down:after,
.landing-page-faq .p-accordion-tab-active .pi-chevron-right:after {
  transform: translate(-50%, -50%) rotate(270deg);
}

.landing-page-faq-accordion-wrapeer .p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 24px 40px 24px 0;
  border-bottom: none;
}
.landing-page-faq-accordion-wrapeer p + p {
  margin-top: 16px;
}

.p-accordion .p-accordion-tab {
  border-bottom: 1px solid var(--dark);
}
.p-accordion .p-accordion-content {
  padding-top: 0;
  padding-left: 0;
  line-height: 1.5;
}

@media (max-width: 1279px) {
  .landing-page-faq-head-text {
    margin-bottom: 16px;
    font-size: 24px;
    text-align: left;
  }
  .landing-page-faq-sub-head-text {
    margin-bottom: 24px;
    font-size: 32px;
    text-align: left;
  }
  .landing-page-pricing-card {
    padding: 24px;
    border-radius: 24px;
  }
}

@media (max-width: 1023px) {
  .landing-page-faq-accordion-wrapeer .p-accordion .p-accordion-header .p-accordion-header-link {
    padding: 16px 40px 16px 3px;
  }
  .landing-page-faq-accordion-wrapeer .p-accordion .p-accordion-header .p-accordion-header-link {
    font-size: 16px;
  }
  .landing-page-faq-accordion-wrapeer .p-accordion .p-accordion-content {
    font-size: 12px;
  }
}
</style>
