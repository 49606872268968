<template>
  <div class="landing-page-subscribe">
    <div class="landing-page-subscribe-head-text">
      Unlock blockchain secrets
    </div>
    <div class="landing-page-subscribe-sub-head-text">
      Stay a leap ahead and discover the hidden trails of crypto transactions
    </div>
    <div class="landing-page-subscribe-actions">
      <gl-input
        v-model="email"
        autocomplete="email"
        class="flex-1"
        :height="50"
        is-block-error-style
        name="Email address"
        placeholder="Email address"
        rules="email"
        @errors="changeErrorsStatus"
      />
      <Button
        class="landing-page-subscribe-actions__button p-button-white"
        :class="{'landing-page-subscribe-actions__button--disabled' : isHasErrors || !email.trim()}"
        icon="pi pi-arrow-up-right"
        icon-pos="right"
        label="Update Me"
        @click="sendEmailSubscribe"
      />
    </div>
  </div>
</template>

<script>
//Components
import GlInput from '@/components/gl-input'
import Button from 'primevue/button'
import { mapActions } from 'vuex'

export default {
  components: {
    GlInput,
    Button,
  },
  data() {
    return {
      email: '',
      isHasErrors: false,
    }
  },
  methods: {
    ...mapActions('subscribe', ['emailSubscribe']),
    sendEmailSubscribe() {
      this.emailSubscribe({
        email: this.email.toLowerCase()
      }).then(() => {
        this.$toast.add({
          severity: 'success',
          summary: 'Success',
          detail: 'You have successfully subscribed',
          life: 3000,
        })
      })
    },
    changeErrorsStatus(validationResult) {
      this.isHasErrors = !validationResult
    }
  },
}
</script>

<style>
.landing-page-subscribe {
  display: flex;
  flex-direction: column;
  width: var(--main-content-width);
  max-width: var(--main-content-max-width);
  background: var(--reflex-bluet);
  border-radius: 24px;
  background-image: url('~@/assets/images/background-subscribe-left.png'),
    url('~@/assets/images/background-subscribe-right.png');
  background-repeat: no-repeat;
  background-position-x: left, right;
  background-position-y: 0, 0;
  background-size: auto 100%;
  padding: 60px;
}

.landing-page-subscribe-head-text {
  text-align: center;
  font-size: 46px;
  font-weight: 700;
  color: var(--white);
  margin-bottom: 30px;
}
.landing-page-subscribe-sub-head-text {
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  color: var(--white);
  margin-bottom: 60px;
}
.landing-page-subscribe-actions {
  display: flex;
  gap: 30px;
  align-self: center;
  margin-bottom: 30px;
  width: 100%;
  max-width: 800px;
}
.landing-page-subscribe-actions__button.p-button {
  padding-left: 37px;
  padding-right: 37px;
}
.landing-page-subscribe-actions__button--disabled {
  opacity: 0.5;
  pointer-events: none;
}
.landing-page-subscribe-actions .gl-input__error {
  font-size: 16px;
  position: absolute;
  left: 0;
  top: 100%;
}

@media (max-width: 1279px) {
  .landing-page-subscribe {
    padding: 24px;
  }
  .landing-page-subscribe-head-text {
    font-size: 24px;
    margin-bottom: 16px;
  }
  .landing-page-subscribe-sub-head-text {
    font-size: 16px;
    margin-bottom: 32px;
  }
  .landing-page-subscribe-actions {
    margin-bottom: 0;
  }
}
@media (max-width: 767px) {
  .landing-page-subscribe-actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }
  .landing-page-subscribe-actions .gl-input__wrapper {
    min-width: 100%;
  }
  .landing-page-subscribe-actions__button {
    max-width: 200px;
  }
}
</style>
