<template>
  <div class="landing-page-pricing">
    <div class="landing-page-pricing-head-text">
      Pricing
    </div>
    <div class="landing-page-pricing-sub-head-text">
      Choose your package
    </div>
    <div
      v-if="loadingPackages"
      class="landing-page-pricing-card-empty-wrapper"
    >
      <gl-loader progress-spinner />
    </div>
    <div
      v-else
      class="landing-page-pricing-card-wrapper"
    >
      <div
        v-for="data in cartsResult"
        :key="data.key"
        class="landing-page-pricing-card"
      >
        <div class="landing-page-pricing-card-main">
          <div class="landing-page-pricing-card-head-text">
            {{ data.name }}
          </div>
          <div class="landing-page-pricing-card-amount">
            <span
              class="bluet-text"
            >{{ paymentCurrency.symbol || '' }}{{ data.price }}
            </span>
            <span><span class="bold">/</span> {{ data.tokens
            }}<gl-icon
              class="ml-1 mr-2"
              :height="24"
              name="lookups-dark"
              :width="24"
            />
            </span>
          </div>
          <div class="flex">
            <div class="landing-page-pricing-card-text flex-1">
              <div
                v-for="(el, i) in data.labels"
                :key="i"
                class="flex"
              >
                <div class="flex">
                  <gl-icon
                    v-if="!i"
                    class="mr-2"
                    :height="16"
                    name="done-blue"
                    :width="16"
                  />
                  <gl-icon
                    v-else
                    class="mr-2"
                    :height="16"
                    name="discount"
                    :width="16"
                  />
                </div>
                <div
                  class="landing-page-pricing-card-text-find-ico"
                  v-html="el.label"
                />
              </div>
            </div>
            <div class="flex align-end">
              <gl-icon
                class="pointer mr-2"
                :height="40"
                name="arrow-forward"
                :width="40"
                @click="$router.push({ name: 'payment', params: {key: data.key}})"
              />
            </div>
          </div>
        </div>

        <div
          v-if="data.packageImage"
          class="landing-page-pricing-card-img-wrapper"
        >
          <img
            :src="require(`@/assets/images/packages/${data.packageImage}`)"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Vuex
import { mapActions, mapGetters } from 'vuex'
//Components
import GlIcon from '@/components/gl-icon'
import GlLoader from '@/components/gl-loader'
//utils
import { getObjectValueByKey } from '@/utils/helpers'
import { packageImageMap } from '@/pages/landing-page/models/landing-data'

export default {
  components: {
    GlIcon,
    GlLoader,
  },
  data() {
    return {
      loadingPackages: false,
      cardsData: [],
    }
  },
  computed: {
    ...mapGetters('user', ['paymentCurrency']),
    cartsResult() {
      return this.cardsData
        .slice()
        .map(item => {
          return {
            ...item,
            packageImage: getObjectValueByKey(packageImageMap, item.key),
          }
        })
        .sort((a, b) => a.price - b.price)
    },
  },
  created() {
    this.loadPackages()
  },
  methods: {
    ...mapActions('payment', ['getPackages']),
    getObjectValueByKey,
    loadPackages() {
      this.loadingPackages = true
      this.getPackages()
        .then(({ data, success }) => {
          if (success) {
            this.cardsData = data
          }
        })
        .finally(() => {
          this.loadingPackages = false
        })
    },
  },
}
</script>

<style>
.landing-page-pricing-card-wrapper {
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(6, 1fr);
}
.landing-page-pricing-card-empty-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
.landing-page-pricing-card-wrapper > div {
  grid-column: span 2;
}
.landing-page-pricing-card-wrapper > div:nth-child(n + 4) {
  grid-column: span 3;
}

.landing-page-pricing {
  display: flex;
  flex-direction: column;
  width: var(--main-content-width);
  max-width: var(--main-content-max-width);
}
.landing-page-pricing-head-text {
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  color: #ffffff50;
  margin-bottom: 30px;
}
.landing-page-pricing-sub-head-text {
  text-align: center;
  font-size: 60px;
  font-weight: 700;
  color: var(--white);
  margin-bottom: 60px;
}

.landing-page-pricing-card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  background: var(--white);
  padding: 40px 40px 40px 24px;
  box-shadow: 0px 0px 10px 0px var(--dark-transparent-1a);
  border-radius: 24px;
}

.landing-page-pricing-card-main {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.landing-page-pricing-card-head-text {
  font-size: 32px;
  font-weight: 700;
  color: var(--dark-text);
}

.landing-page-pricing-card-amount {
  font-weight: 700;
  font-size: 32px;
  font-family: 'Montserrat';
}

.landing-page-pricing-card-text {
  font-size: 14px;
  font-weight: 500;
  color: var(--dark-text);
  line-height: 16px;
}
.landing-page-pricing-card-text > div:not(:last-child) {
  margin-bottom: 12px;
}

.landing-page-pricing-card-img-wrapper {
  position: absolute;
  top: -32px;
  right: -20px;
}

.landing-page-pricing-card:nth-child(n + 4)
  .landing-page-pricing-card-img-wrapper {
  top: -20px;
}

.landing-page-pricing-card-img-wrapper img {
  width: 150px;
  filter: drop-shadow(0px 8px 10px rgba(14, 11, 61, 0.1));
}

.landing-page-pricing-card:nth-child(2)
  .landing-page-pricing-card-img-wrapper
  img {
  width: 135px;
}

.landing-page-pricing-card-text-find-ico span {
  margin-left: 4px;
  padding-left: 20px;
  position: relative;
}
.landing-page-pricing-card-text-find-ico span:before {
  content: '';
  display: block;
  width: 16px;
  height: 17px;
  background: url('~@/assets/images/lookup-icon.png');
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
}

@media (max-width: 1279px) {
  .landing-page-pricing-head-text {
    margin-bottom: 16px;
    font-size: 24px;
    text-align: left;
  }
  .landing-page-pricing-sub-head-text {
    margin-bottom: 44px;
    font-size: 32px;
    text-align: left;
  }
  .landing-page-pricing-card {
    padding: 24px;
    border-radius: 24px;
  }
  .landing-page-pricing-card-img-wrapper img {
    width: 125px;
  }
  .landing-page-pricing-card:nth-child(2)
    .landing-page-pricing-card-img-wrapper
    img {
    width: 115px;
  }
}

@media (max-width: 1023px) {
  .landing-page-pricing-card-wrapper > div {
    grid-column: span 3;
  }
  .landing-page-pricing-card-wrapper > div:nth-child(5) {
    grid-column: span 6;
  }
  .landing-page-pricing-card:nth-child(n + 3)
    .landing-page-pricing-card-img-wrapper {
    top: -20px;
  }
}

@media (max-width: 767px) {
  .landing-page-pricing-card-wrapper > div {
    grid-column: span 6;
  }
  .landing-page-pricing-card-wrapper > div:nth-child(4) {
    grid-column: span 6;
  }

  .landing-page-pricing-card-img-wrapper {
    top: -20px;
  }

  .landing-page-pricing-card-img-wrapper {
    right: -10px;
  }
  .landing-page-pricing-card-img-wrapper img {
    width: 95px;
  }
  .landing-page-pricing-card:nth-child(2)
    .landing-page-pricing-card-img-wrapper
    img {
    width: 90px;
  }
}
</style>
